import React, {useEffect, useState} from "react";
import store from "store2";
import { useArtContext } from "../contexts/ArtContext";

export default function BgGradient ({selBg}) {
  const {bgType} = useArtContext();

  useEffect( () => {
    selBg && store.set('selBg', {data: selBg, type: bgType})
  }, [selBg]);


  return(
    <div 
      className={`bg-gradient`}
      style={{backgroundImage: selBg}}>
    </div>
  )
}

