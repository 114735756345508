import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import IconImage from '../assets/icons/IconImage';
import { useArtContext } from '../contexts/ArtContext';


export default function DropZone( {setSrc} ) {

  const {setBgType} = useArtContext();

  const onDrop = useCallback(acceptedFiles => acceptedFiles.map(f => {
      var reader = new FileReader();
      reader.readAsDataURL(f); 
      reader.onloadend = function() {
        const base64data = reader.result;    
        setSrc(base64data);
        setBgType('user');
      };
    }), []);

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    }
  });

  return (
    <span title="Upload Background Image" className='dz'>
      <span className='dz__area' {...getRootProps()}>
        <div className='dz__label'></div>
        <input {...getInputProps()} />
      </span>
      <IconImage />
    </span>
  )
}

