import React from "react";
import { useArtContext } from "../contexts/ArtContext";
import { nanoid } from "nanoid";
import { bgList } from "../lib/color-helpers";
import { bgThumbs } from "../assets/bgs/bgs";

export default function Palettes() {
  const { setSelBg, setBgType, palettesOpen, setPalettesOpen, palNum, setPalNum } = useArtContext();
  const list = bgList();

  return (
    <div className={`palettes--wrapper`}>
      <div className={`palettes`}>
        {list.map(({t, nr, bg}, i) => (
          list[i].t !== list[i-1]?.t 
          ?  (
          <span key={nanoid()} className="break"></span>
          ) : (
          <span 
            key={nanoid()} 
          >
            {t !== 'img'
              ? (
                <span 
                  key={nanoid()}
                    className={`palette ${t} ${nr === palNum ? "selected" : ""}`}
                    onClick={() => {
                      setSelBg(bg);
                      setBgType(t);
                      setPalNum(nr);
                      setPalettesOpen(!palettesOpen);
                    }}
                    style={{ backgroundImage: bg }}
                  >
                </span>
              ) : (
                <span
                  key={nanoid()}
                  className={`palette ${t} ${i === palNum ? "selected" : "unselected"}`}
                  onClick={() => {
                    setSelBg(bgThumbs[palNum]);
                    setBgType(t);
                    setPalNum(nr);
                    setPalettesOpen(!palettesOpen);
                  }}
                >
                  <img src={bgThumbs[nr]} width="38" height="38" alt="bg" />
                </span>
              )}
            </span>   
          )
        ))}
      </div>
    </div>
  );
}