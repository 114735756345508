import React from "react";

export default function IconEmail() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
      style={{enableBackground: `new 0 0 48 48`}}
      xmlSpace="preserve"
    >
      <path
        fill="currentColor"
        d="M7,40c-0.8,0-1.5-0.3-2.1-0.9C4.3,38.5,4,37.8,4,37V11c0-0.8,0.3-1.5,0.9-2.1C5.5,8.3,6.2,8,7,8h34c0.8,0,1.5,0.3,2.1,0.9
	C43.7,9.5,44,10.2,44,11v26c0,0.8-0.3,1.5-0.9,2.1C42.5,39.7,41.8,40,41,40H7z M24,24.9L7,13.8V37l0,0l0,0h34l0,0l0,0V13.8L24,24.9z
	 M24,21.9L40.8,11H7.2L24,21.9z M7,13.8V11V13.8V37l0,0l0,0l0,0l0,0V13.8z"
      />
    </svg>
  );
}
