import React from "react";
import Page from "../Page";
import Snippet from "../components/Snippet";

export default function CreditsPage() {

  const pp = `## Credits

* Our deep gratitude to [MintMe project](https://mintme.global) for invaluable help with implementing their NFT minting service 
* Many thanks to [Charles Ojukwu](https://github.com/cojdev) for his advanced, highly professional [LowpPoly Generator](https://github.com/cojdev/lowpoly)

### Poems  used: 

* Adam's Curse By William Butler Yeats
* The Waste Land By T. S. Eliot
* Lost by David Vagoner
* Čas by Otokar Březina`;

  return (
    <Page className={`credits-page`}>
        <div className="bg-m">
        </div>
        <div className="box">
          <Snippet md={pp} />
        </div>
    </Page>
  );
}

