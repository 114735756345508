import React, { useState, useRef, useEffect } from "react";
import domtoimage from "dom-to-image-more";
import { saveAs } from "file-saver";
import html2canvas from 'html2canvas';
import { nanoid } from "nanoid";
import Page from "../Page";
import BgUserImage from "./BgUserImage";
import BgGradient from "./BgGradient";
import BgImage from "./BgImage";
import BgPoly from "./BgPoly";
import Textboard from "./Textboard";
import Controls from "./Controls";
import Palettes from "./Palettes";
import { colorPalettes, makeBg } from "../lib/color-helpers";
import { useArtContext } from "../contexts/ArtContext";
import store from "store2";
import { useTranslation } from "react-i18next";
import { generatePo } from "../lib/langprocessing";
// import usePrev from "../hooks/usePrev";
import { useNotify } from "../contexts/NotifyContext";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { enMatrix } from "../lib/en-matrix";
import colorGradientIcon from "../assets/icons-png/colorGradientIcon.png";
import bgGradientIcon from "../assets/icons-png/bgGradientIcon.png";
import dndGradientIcon from "../assets/icons-png/dndGradientIcon.png";
import draftGradientIcon from "../assets/icons-png/draftGradientIcon.png";
import editGradientIcon from "../assets/icons-png/editGradientIcon.png";
import downloadGradientIcon from "../assets/icons-png/downloadGradientIcon.png";
import nftGradientIcon from "../assets/icons-png/nftGradientIcon.png";
import refreshGradientIcon from "../assets/icons-png/refreshGradientIcon.png";
import IconToggleLeft from "../assets/icons/IconToggleLeft";
import { stopwords } from "../lib/en-matrix";
import Logo from "./TopNav";
import "./Edit.scss";
import Loader from "./Loader";

export default function EditPage() {
  const {
    selBg,
    setSelBg,
    bgType,
    setBgType,
    secOpen,
    setSecOpen,
    palettesOpen,
    setPalettesOpen,
  } = useArtContext();
  const { i18n } = useTranslation();
  const [poem, setPoem] = useState(store.has("poem") ? store.get("poem") : "");
  const [seed, setSeed] = useState(store.has("seed") ? store.get("seed") : enMatrix["noun"].random());
  const [vocab, setVocab] = useState("");
  const [author, setAuthor] = useState("OpenAI");
  const [drafts, setDrafts] = useState([]);
  const [editing, setEditing] = useState(false);
  const [fontSize, setFontSize] = useState(1.5);
  const [fontColor, setFontColor] = useState(true);
  const [src, setSrc] = useState("");
  const [loading, setLoading] = useState(false);

  const { w, h } = useWindowDimensions();
  const { setNotification } = useNotify();

  const lang = i18n.language;
  const resRef = useRef(null);
  const pals = [];

  const load = () => {
    !poem && store.has("poem") && setPoem(store.get("poem"));
    !author && store.has("author") && setAuthor(store.get("author"));
  };

  useEffect(() => {
    (async () => {
      if (!poem) {
        setLoading(true);
        load();
        let po = await generatePo(lang, seed);
        setVocab(po);
        createPoem(seed, po);
      }
    })();
  }, [lang]);

  const createPoem = async (seed, po) => {
    const genre = store.get("genre") ?? "quatrain";
    let genreStr = "";

    switch (genre) {
      case "quatrain":
        genreStr = "four-line quatrain in Nostradamus style";
        break;
      default:
        genreStr = genre;
    }

    let extract = [];
    po?.split(" ").map((word, idx) => {
      if (
        !stopwords.includes(word) &&
        word.length > 2 &&
        idx < 3 &&
        word.match(/^[a-zA-Z]+$/)
      ) {
        extract.push(word);
      }
    });
    let vocabStr = '';
    const voca = extract.join('", "');
    voca.length > 0 ? vocabStr = `based on some of these words: "${voca}"` : vocabStr = '';

    const prompt = `Compose a ${genreStr} about ${seed} ${vocabStr}`;
    // console.log(prompt);

    try {
      const response = await fetch(
        "https://dash.martlet.live/wp-json/dash/v1/gen_text",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Basic " + btoa(`media:${process.env.REACT_APP_MEDIA_KEY}`),
          },
          body: JSON.stringify({
            model: "gpt-4o",
            messages: [{ role: "system", content: "You are a modern poet. You job is make poems using the words you receive from your patron as themes." },
            { role: "user", content: prompt },
            ],         
          }),
        }
      );

      const data = await response.json();
      // console.log(data);

      setPoem(data.choices[0].message.content);
      store.set("poem", data.choices[0].message.content);
      setNotification("Poem created successfully. Edit as you like!");
    } catch (error) {
      setNotification("No response from the server.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (store.has("fontSize")) {
      setFontSize(store.get("fontSize"));
    }
    if (store.has("fontColor")) {
      setFontColor(store.get("fontColor"));
    }
    if (!store.has("selBg")) {
      setSelBg(makeBg(colorPalettes[0], "gradient1"));
      setBgType("gradient1");
    } else {
      const selBg = store.get("selBg");
      if (selBg && selBg?.type) {
        setBgType(selBg.type);

        if (["gradient1", "gradient2", "lowpoly"].includes(selBg.type)) {
          setSelBg(selBg.data);
        } else if (["img", "user"].includes(selBg.type)) {
          setSrc(selBg.data);
        }
      }
    }
  }, []);

  const crease = (direction, size) => {
    const max = 7;
    const min = 1;
    const nextSize =
      direction === "in" && size + 0.5 <= max
        ? size + 0.5
        : direction === "de" && size - 0.5 >= min
        ? size - 0.5
        : size;
    setFontSize(nextSize);
    store.set("fontSize", nextSize);
  };

  const makeBlob = async () => {
    return await domtoimage.toBlob(resRef.current), {
      style: {
        transform: 'none',
        margin: '0', // Reset any margins or transformations
        // other styles as needed
      },
      bgcolor: 'white', // Ensure background color is set
    
    };
  };

  const save  = async () => {
    await domtoimage.toPng(resRef.current, {
      bgcolor: 'white', // Set a background color if needed
      style: {
        transform: 'none', // Ensure no transformations affect the output
        margin: '0', // Reset margin
      },
      width: resRef.current.scrollWidth, // Use scrollWidth and scrollHeight if content overflows
      height: resRef.current.scrollHeight,
      cacheBust: true, // To prevent caching issues
    })
    .then((dataUrl) => {
      saveAs(dataUrl, `RichPoet-${Date.now()}.png`);
    })
    .catch((error) => {
      console.error('Error capturing image:', error);
    });
  };

  
  const onUpload = (f) => {
    var reader = new FileReader();
    reader.readAsDataURL(f);
    reader.onloadend = function () {
      const base64data = reader.result;
      setSrc(base64data);
    };
  };

  return (
    <Page className={`edit-page ${w > h ? "horizontal" : "vertical"}`}>
      <div className={`secondary-content${secOpen ? " expand" : " collapse"}`}>
        <Logo />

        <span className={`sec-toggle${secOpen ? " look-left" : " look-right"}`}>
          <span onClick={() => setSecOpen(!secOpen)} className={`icon`}>
            <IconToggleLeft />
          </span>
        </span>

        <div className="asides">
          <div className="aside">
            <h2 className="tagline">Handy Hints</h2>

            <div className="features">
              <div className="feature">
                <img
                  className="feature-icon"
                  src={editGradientIcon}
                  width="64"
                  height="64"
                />
                <p>Click the Edit button to unlock editing </p>
              </div>
              <div className="feature">
                <img
                  className="feature-icon"
                  src={refreshGradientIcon}
                  width="64"
                  height="64"
                />
                <p>
                  Generate new words by clicking the Refresh button (the poem
                  will be saved as draft)
                </p>
              </div>
              <div className="feature">
                <img
                  className="feature-icon"
                  src={dndGradientIcon}
                  width="64"
                  height="64"
                />
                <p>Move poem text and author name as needed</p>
              </div>
              <div className="feature">
                <img
                  className="feature-icon"
                  src={draftGradientIcon}
                  width="64"
                  height="64"
                />
                <p>Make drafts to save fragments or multiple versions</p>
              </div>
              <div className="feature">
                <img
                  className="feature-icon"
                  src={colorGradientIcon}
                  width="64"
                  height="64"
                />
                <p>
                  Invert the poem color by clicking "A" button, change font size
                  with plus and minus buttons
                </p>
              </div>
              <div className="feature">
                <img
                  className="feature-icon"
                  src={bgGradientIcon}
                  width="64"
                  height="64"
                />
                <p>Add background image by opening background palette</p>
              </div>
              <div className="feature">
                <img
                  className="feature-icon"
                  src={bgGradientIcon}
                  width="64"
                  height="64"
                />
                <p>You can also upload your own background image</p>
              </div>
              <div className="feature">
                <img
                  className="feature-icon"
                  src={downloadGradientIcon}
                  width="64"
                  height="64"
                />
                <p>
                  When done, click Download button to download your poem as a
                  PNG image
                </p>
              </div>
              <div className="feature">
                <img
                  className="feature-icon"
                  src={nftGradientIcon}
                  width="64"
                  height="64"
                />
                <p>
                  Make an NFT from your image to preserve your auhorshop on
                  immutable blockchain
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`primary-content${secOpen ? " collapse" : " expand"}`}>
        <Controls
          poem={poem}
          setPoem={setPoem}
          vocab={vocab}
          setVocab={setVocab}
          author={author}
          setAuthor={setAuthor}
          editing={editing}
          setEditing={setEditing}
          fontColor={fontColor}
          setFontColor={setFontColor}
          fontSize={fontSize}
          crease={crease}
          save={save}
          pals={pals}
          makeBg={makeBg}
          setPalettesOpen={setPalettesOpen}
          palettesOpen={palettesOpen}
          onUpload={onUpload}
          setBgType={setBgType}
          drafts={drafts}
          setDrafts={setDrafts}
          src={src}
          setSrc={setSrc}
          makeBlob={makeBlob}
        />

        {palettesOpen && <Palettes />}

        {loading ? (
          <Loader className="box" setLoading={setLoading} />
        ) : (
          <div className="res" ref={resRef}>
            <div className="editor">
              <Textboard
                poem={poem}
                setPoem={setPoem}
                author={author}
                setAuthor={setAuthor}
                editing={editing}
                fontSize={fontSize}
                fontColor={fontColor}
              />
            </div>

            <div className={`artboard${secOpen ? " collapse" : " expand"}`}>
              {["gradient1", "gradient2"].includes(bgType) && (
                <BgGradient selBg={selBg} />
              )}
              {bgType === "img" && <BgImage />}
              {bgType === "lowpoly" && <BgPoly />}
              {bgType === "user" && <BgUserImage src={selBg?.data || src} />}
            </div>
          </div>
        )}
      </div>
    </Page>
  );
}
