import React, {
  useState,
  createContext,
  useContext,
} from "react";

const ArtContext = createContext({
  selBg: '',
  setBSelg: () => {},
  bgType: '',
  setBgType: () => {},
  secOpen: () => {},
  setSecOpen: () => {},
  start: false,
  setStart: () => {},
  palettesOpen: false, 
  setPalettesOpen: () => {},
  palNum: 0,
  setPalNum: () => {},
});

function ArtProvider({ children }) {
  const [selBg, setSelBg] = useState('');
  const [bgType, setBgType] = useState('gradient2');
  const [secOpen, setSecOpen] = useState(true);
  const [start, setStart] = useState(false);
  const [palettesOpen, setPalettesOpen] = useState(false);
  const [palNum, setPalNum] = useState(0);

  const values = {
    selBg,
    setSelBg,
    bgType,
    setBgType,
    secOpen, 
    setSecOpen,
    start,
    setStart,
    palettesOpen, 
    setPalettesOpen,
    palNum,
    setPalNum,
  };

  return <ArtContext.Provider value={values}>{children}</ArtContext.Provider>;
}

export { ArtProvider, ArtContext };

export const useArtContext = () => useContext(ArtContext);
