import React from "react";
import DOMPurify from "dompurify";
import poems from "../lib/poems";
import { stopwords } from "../lib/en-matrix";
import { normalize, clean } from "../lib/langprocessing";
import { useNavigate } from "react-router-dom";
import store from "store2";
import "./LetterDance.scss";

Array.prototype.random = function () {
  return this[Math.floor(Math.random() * this.length)];
};

export default function LetterDance({}) {
  const format = "by-word";
  const navigate = useNavigate();
  const lang = store.get("lang") ?? "en";

  return (
    <div className={`letter-dance`}>
      <div className={`letterize ${format}`}>
        {poems[lang].text.split("\n").map((line, idx) => (
          <span key={idx} className={`line`}>
            {line.split(" ").map((word, ix) =>
              !stopwords.includes(word.toLowerCase()) && word.length > 2 ? (
                <span
                  key={ix}
                  className={`word spot`}
                  onClick={() => {
                    store.set("seed", normalize(clean(word)));
                    navigate("/edit");
                  }}
                >
                  {word.split("").map((letter, i) => (
                    <span key={i} className={`letter`}>
                      {letter}
                    </span>
                  ))}
                </span>
              ) : (
                <span key={ix} className={`word`}>
                  {word.split("").map((letter, i) => (
                    <span key={i} className={`letter`}>
                      {letter}
                    </span>
                  ))}
                </span>
              )
            )}
          </span>
        ))}
      </div>
    </div>
  );
}
