import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./BurgMenu.scss";

export default function BurgMenu() {
  const {t} = useTranslation();
 
  return (
    <div className="burg-menu">

      <a className="burg-menu__item" href={'/'}>
        <span>Home</span>
      </a>

      <Link className="burg-menu__item" to="/assets">
        My Assets
      </Link>

    </div>
  );
}
