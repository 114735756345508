import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import store from 'store2';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: store.get('lang') || 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translations: require('./en/translations.json')
      },
      cs: {
        translations: require('./cs/translations.json')
      }
    },
  ns: ['translations'],
  defaultNS: 'translations'
  });

export default i18n;

