import React from 'react';
import MessagePanel from './components/MessagePanel';
import TopNav from './components/TopNav';
import Footer from './components/Footer';
import useCurrentPage from './hooks/useCurrentPage';
import './Page.scss';

export default function Page({ children, className }) {

  const currentPage = useCurrentPage();

  return (
    <div className="page">
      <MessagePanel />
      {currentPage !== 'edit' && <TopNav />}
      <article className={`primary ${className ?? ''}`}>
        <main className="main">
          {children}
        </main>
      </article>
      <Footer />
    </div>
  );
}
