import React from "react";
import { createRoot } from 'react-dom/client';
import "./locales/i18n-config";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ArtProvider } from "./contexts/ArtContext";
import { NotifyProvider } from "./contexts/NotifyContext";
import { MetaMaskProvider } from "metamask-react";
import App from "./App";
import "./app.css";

const root = createRoot( document.getElementById("root")); 

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <MetaMaskProvider>
          <NotifyProvider>
            <ArtProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ArtProvider>
          </NotifyProvider>
      </MetaMaskProvider>
    </HelmetProvider>
  </React.StrictMode>
  );


