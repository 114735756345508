import axios from "axios";
import DOMPurify from "dompurify";
import {enMatrix} from "./en-matrix";
import {csMatrix} from "./cs-matrix";


export const normalize = (input) => input?.toLowerCase().replace(/[^a-z\s]+/g, "");

export const clean = (input, minChars = 1, maxChars = 256) => {
  if (
    typeof input === "string" &&
    input.length > minChars &&
    input.length < maxChars
  ) return DOMPurify.sanitize(input.trim());
}  


export async function getRelatives (sanitizedWord) {
  const str = sanitizedWord.indexOf(" ")
    ? sanitizedWord.split(" ")[0]
    : sanitizedWord;

  if (!str?.length)
    return;

  let endpoint = `https://www.dictionaryapi.com/api/v3/references/thesaurus/json/${str}?key=${process.env.REACT_APP_MW_KEY}`;
  
  const wordLib = {
    noun: [],
    verb: [],
    adjective: [],
    adverb: [],
  };
  const matrix = enMatrix;

  const extract = (what, fl) => { // accepts d.meta.something
    if ( !Array.isArray(what[0])) // data.meta.syns['word']
    what.map( word => 
      wordLib[fl].push(word)
    );
    else if (Array.isArray(what[0]) && what[0].length > 0 && !Array.isArray(what[0][0])) { // data.meta.syns[0]['word']
      what.map(arr => arr.map( word => {
        let w = word.replace(/[^a-z\s-]+/g, "");
        if (matrix[fl].includes(w) || [1, 2, 3, 4].random() === 1 )
          wordLib[fl].push(w);
      }))
    }
  }

  await axios.get(endpoint).then( (r) => {
    if (Array.isArray(r.data) ) {
      r.data.map(d => {
        if (d.fl && Object.keys(wordLib).includes(d.fl) && d.meta && d.meta?.syns.length > 0) 
          extract(d.meta.syns, d.fl);
        if (d.fl && Object.keys(wordLib).includes(d.fl) && d.meta && d.meta?.ants.length > 0) 
          extract(d.meta.ants, d.fl);
      })}})
     .catch( e => console.log(e));
    return Object.values(wordLib) !== [] ? wordLib : null;
  }


// GENERATE 
export async function generatePo (lang = 'en', sanitized = '', ) {
  const sanitizedWord = normalize(sanitized);
  let wordLib;
  
  if (lang === 'cs') {
    wordLib = csMatrix;
  } else {

    wordLib = await getRelatives(sanitizedWord);
    if (!wordLib) {
      wordLib = {
        noun: [],
        verb: [],
        adjective: [],
        adverb: [],
      };
    }
    const minWords = 10;
    Object.keys(wordLib).map((key) => {
      if (wordLib[key] === [] || wordLib[key].length < minWords) {
        do {
          wordLib[key].push(enMatrix[key].length > 0 && enMatrix[key].random());
        } while (wordLib[key].length < minWords);
      }
    });
  }

  const usedWords = [];
  const fv = wordLib["verb"].random();
  const fn = wordLib["noun"].random();
  usedWords.push(fv);
  usedWords.push(fn);

  const r = (fl) => {
    function add() {
      let w = wordLib[fl].random();
      if (!usedWords.includes(w)) {
        usedWords.push(w);
        return w;
      } else return false;
    }
    for (let j = 0; j < 7; j++) {
      let res = add();
      if (res) return res;
    }
  };

  const l2f = {
    V: "verb",
    N: "noun",
    A: "adjective",
    D: "adverb",
  };

  // make quatrain
  const structures = [
    "VAN AN VNAN", 
    "VAN NAN VAN", 
    "VAN NAN ANN", 
    "VN VD ANN"
  ];

  let structure = structures.random();

  let result = "";

  for (let i = 0; i < structure.length; i++) {
    if (structure.charAt(i) === " ") result += "LBR";
    else result += `${r(l2f[structure.charAt(i)])} `;
  }

  const po = `${result.replace(/LBR/g, "  \n")}  \n${fv} ${sanitizedWord ?? ''} ${fn}`;

  // console.log('po', po);
  return po;
}
