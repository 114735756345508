import React from "react";
import AvatarEditor from 'react-avatar-editor';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

export default function BgUserImage ({src}) {

const {w, h} = useWindowDimensions();

  return ( 
  <AvatarEditor
    className="bg-user"
    image={src}
    width={w}
    height={h}
    border={0}
    color={[255, 255, 255, 0.6]} 
    rotate={0}
  />
  )
}
