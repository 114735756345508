import { PRNG, rgb2hex, hsl2hex } from "../lib/lowpoly-lib";

class Triangle {
  constructor(vertices) {
    this.vertices = vertices;
  }

  getCentre() {
    const { vertices } = this;
    const x = (vertices[0].x + vertices[1].x + vertices[2].x) / 3;
    const y = (vertices[0].y + vertices[1].y + vertices[2].y) / 3;
    return { x, y };
  }
}

export default class LowPoly {
  constructor(
    element,
    width,
    height,
    alpha,
    variance,
    depth,
    dither,
    cellSize,
    colors,
    length
  ) {

    this.element = element;
    this.points = [];
    this.triangles = [];
    this.w = width;
    this.h = height;
    this.c = colors;
    this.l = length;
    this.a = alpha;
    this.variance = variance;
    this.depth = depth;
    this.dither = dither;
    this.cellSize = cellSize;

    this.triangleFill = null;
    this.gridWidth = 0;
    this.gridHeight = 0;
    this.columnCount = 0;
    this.rowCount = 0;
  }

  drawTriangle(vertices, ctx) {
    ctx.beginPath();
    ctx.moveTo(vertices[0].x, vertices[0].y);
    ctx.lineTo(vertices[1].x, vertices[1].y);
    ctx.lineTo(vertices[2].x, vertices[2].y);
    ctx.closePath();
    ctx.fillStyle = this.triangleFill;
    ctx.fill();
  }

  drawBackground() {
    const { ctx, w, h, c, l } = this;
    ctx.clearRect(0, 0, w, h);

    // using a gradient
    ctx.globalCompositeOperation = "multiply";

    // loop colors and create gradient
    let gradient = ctx.createLinearGradient(0, 0, w, h, c);
    for (let i = 0; i < l; i++) {
      // console.log(hsl2hex( c[i]));
      gradient.addColorStop(i / (l - 1), hsl2hex( c[i]));
    }

    // draw gradient on element
    ctx.fillStyle = gradient;
    ctx.beginPath();
    ctx.fillRect(0, 0, this.w, this.h);
    ctx.closePath();
    ctx.fill();

    // draw gradient overlay
    const overlay = ctx.createLinearGradient(0, 0, 0, this.h);
    overlay.addColorStop(0, "#fff");
    overlay.addColorStop(1, "#ccc");

    ctx.beginPath();
    ctx.fillStyle = overlay;
    ctx.fillRect(0, 0, this.w, this.h);
    ctx.closePath();
    ctx.fill();
    ctx.globalCompositeOperation = "source-over";

    return new Promise((resolve) => {
      resolve();
    });
  }

  drawPoly(cell) {
    const { ctx, depth, dither, w, h } = this;

    const centre = cell.getCentre();
    // const random = new PRNG(0);
    const ditherX = (dither / 200) * this.w;
    const ditherY = (dither / 200) * this.h;

    centre.x += Math.random() * ditherX - ditherX / 2;
    centre.y += Math.random() * ditherY - ditherY / 2;

    // boundaries
    if (centre.x < 0) centre.x = 0;
    if (centre.x > this.w - 1) centre.x = this.w - 1;
    if (centre.y < 0) centre.y = 0;
    if (centre.y > this.h - 1) centre.y = this.h - 1;

    const pixelIndex = (Math.floor(centre.x) + Math.floor(centre.y) * w) * 4; // centre.y  * w * 4 + centre.x * 4;

    const [red, green, blue] = [
      this.imageData[pixelIndex],
      this.imageData[pixelIndex + 1],
      this.imageData[pixelIndex + 2],
    ];

    const temp = Math.random() * 2 * depth - depth;

    let r = Math.round(red - red * temp);
    let g = Math.round(green - green * temp);
    let b = Math.round(blue - blue * temp);

    // let alpha = ((this.a * 255) | (1 << 8)).toString(16).slice(1);

    this.triangleFill = rgb2hex(r, g, b);

    this.drawTriangle(cell.vertices, this.ctx);
  }

  generatePoints() {
    const { rowCount, columnCount, cellSize, variance } = this;
    const ret = [];

    const random = new PRNG(0);

    for (let i = 0; i < rowCount; i++) {
      for (let j = 0; j < columnCount; j++) {
        const temp = {};
        // get y position and add variance
        temp.y = i * cellSize * 0.866 - cellSize;
        temp.y += (random.generate() - 0.5) * variance * cellSize * 2;
        // even rows
        if (i % 2 === 0) {
          temp.x = j * cellSize - cellSize;
          temp.x += (random.generate() - 0.5) * variance * cellSize * 2;
        } else {
          // odd rows
          temp.x = j * cellSize - cellSize + cellSize / 2;
          temp.x += (random.generate() - 0.5) * variance * cellSize * 2;
        }

        ret.push(temp);
      }
    }

    this.points = ret;
  }

  generateTriangles() {
    const { points, rowCount, columnCount } = this;

    const ret = [];

    for (let i = 0; i < points.length; i++) {
      const currentRow = Math.floor(i / columnCount);

      // don't add squares/triangles to the end of a row
      if (
        i % columnCount !== columnCount - 1 &&
        i < (rowCount - 1) * columnCount
      ) {
        const square = [
          points[i],
          points[i + 1],
          points[columnCount + i + 1],
          points[columnCount + i],
        ];

        let tri1;
        let tri2;

        // create two triangles from the square;
        if (currentRow % 2 !== 0) {
          tri1 = new Triangle([square[0], square[2], square[3]]);
          tri2 = new Triangle([square[0], square[1], square[2]]);
        } else {
          tri1 = new Triangle([square[0], square[1], square[3]]);
          tri2 = new Triangle([square[1], square[2], square[3]]);
        }

        ret.push(tri1, tri2);
      }
    }

    this.triangles = ret;
  }

  async render() {
    this.ctx = this.element.getContext("2d");

    this.cellSize = this.cellSize * 3 + 30;
    this.variance /= 100;
    this.depth /= 200;

    this.gridWidth = this.w + this.cellSize * 2;
    this.gridHeight = this.h + this.cellSize * 2;

    this.columnCount = Math.ceil(this.gridWidth / this.cellSize) + 2;
    this.rowCount = Math.ceil(this.gridHeight / (this.cellSize * 0.865));

    this.generatePoints();
    this.generateTriangles();

    const { ctx, triangles } = this;

    ctx.clearRect(0, 0, this.w, this.h);

    await this.drawBackground(this.element);

    this.imageData = ctx.getImageData(0, 0, this.w, this.h).data;

    for (let i = 0; i < triangles.length; i++) {
      this.drawPoly(triangles[i]);
    }
  }
}
