import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, MenuButton } from "@szhsin/react-menu";
import BurgMenu from "./BurgMenu";
import IconBurg from "../assets/icons/Burg";
import "./TopNav.scss";
import "@szhsin/react-menu/dist/index.css";
import store from "store2";
import { useTranslation } from "react-i18next";
import useCurrentPage from "../hooks/useCurrentPage";
import logoImg from "../assets/images/a-3.png";

export function Logo() {
  const i18n = useTranslation();
  const [logo, setLogo] = useState("Stanza");
  const words = [
      "Stanza",
  ];

  useEffect( () => {
    let n = store.has("n") ? store.get("n") : 0;
    setLogo(words[n]);
    store.set("n", n + 1 < words.length ? n + 1 : 0);
  }, []);


  return (
    <span className="logo">
      <Link to="/"><img className="logo-img" src={logoImg} alt="" /></Link>
      <span className="word-play"><Link to="/">{logo}</Link></span>
    </span>
  );
}

export function Burger () {
  return (
    <Menu
      menuButton={
        <MenuButton>
          <IconBurg />
        </MenuButton>
      }
    >
    <BurgMenu />
  </Menu>
  )
}

export default function TopNav() {

  const currentPage = useCurrentPage();

  return (
    <div className={`topnav--wrapper`}>      
      <div className="topnav">
        <div className="fip">
          <Logo />
        </div>
        {currentPage !== "edit" && 
          <div className="nav">
            <a href="https://martlet.live/"><button className="lt-btn m">Blog</button></a>
          </div>
    }
      </div>
    </div>
  )
}

