import React from "react";
import Page from "../Page";
import Snippet from "../components/Snippet";
import { capitalize } from "../lib/utils";


export default function DisclaimerPage() {

  const site = process.env.REACT_APP_SITE;
  const disclaimer = `
# Disclaimer

The information by Rich ${capitalize(site)} Project (we," "us," or "our") on https://rich${site}.space (the "Site") is for general information purposes only. 
  While we endeavour to keep the information provided on the  site up to date and correct, we make no representations or warranties of any kind, 
  express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, 
  or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
  In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever 
  arising from loss of data or profits arising out of, or in connection with, the use of this website.

Authors of NFTs minted on the Site or listed on the Site solely bear the whole responsibility for their content.

Through this website you are able to link to other websites which are not under our control. 
  We have no control over the nature, content and availability of those sites. 
  The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
  
Every effort is made to keep the website up and running smoothly. However, we takes no responsibility for, and will not be liable for, the website being unavailable 
  due to technical issues.`;

  return (
    <Page className={`disclaimer-page`}>
      <div className="bg-m">
      </div>
      <div className="box">
        <Snippet md={disclaimer} />
      </div>
    </Page>
  );
}

