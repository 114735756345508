import React, { useEffect } from "react";
import { useArtContext } from "../contexts/ArtContext";
import { bgThumbs } from "../assets/bgs/bgs";
import { makeBg , colorPalettes} from "../lib/color-helpers";

export default function ColorToggle() {

  const {palettesOpen, setPalettesOpen, selBg, setSelBg, palNum, bgType} = useArtContext();
        
  useEffect ( () => {
    setSelBg(makeBg(colorPalettes[palNum], bgType));
  }, [palNum, bgType]);

  return (
    bgType === 'img' ? 
    <span
      className={`btn-icon color-toggle`}
      onClick={() => {
        setPalettesOpen(!palettesOpen);
      }}
    >
      <img src={bgThumbs[palNum]} alt="" width="100%" height="100%" />
    </span>
    :
    <span 
      className={`btn-icon color-toggle`}
      onClick={() => {
        setPalettesOpen(!palettesOpen);
      }}
      style={{ backgroundImage: selBg }}
    ></span>
  );
}
