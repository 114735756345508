import bg01 from './bg-01.jpg';
import bg02 from './bg-02.jpg';
import bg03 from './bg-03.jpg';
import bg04 from './bg-04.jpg';
import bg05 from './bg-05.jpg';
import bg06 from './bg-06.jpg';
import bg07 from './bg-07.jpg';

import thumb_bg01 from './thumb-bg-01.jpg';
import thumb_bg02 from './thumb-bg-02.jpg';
import thumb_bg03 from './thumb-bg-03.jpg';
import thumb_bg04 from './thumb-bg-04.jpg';
import thumb_bg05 from './thumb-bg-05.jpg';
import thumb_bg06 from './thumb-bg-06.jpg';
import thumb_bg07 from './thumb-bg-07.jpg';

export const bgs = [
  bg01, bg02, bg03, bg04, bg05, bg06, bg07
];
export const bgThumbs = [
  thumb_bg01, thumb_bg02, thumb_bg03, thumb_bg04, thumb_bg05, thumb_bg06, thumb_bg07
];