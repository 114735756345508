
export const shuffle = (list) => list.sort(() => Math.random() - 0.5);

export const randNum = (min, max) =>
  Math.floor(Math.random() * (max - min) + min);
export const randElm = (arr) => arr[randNum(0, arr.length - 1)];
export const range = (start, end) =>
  Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);

    const chart = [
      [ 'M', 1000],
      ['CM',  900],
      [ 'D',  500],
      ['CD',  400],
      [ 'C',  100],
      ['XC',   90],
      [ 'L',   50],
      ['XL',   40],
      [ 'X',   10],
      ['IX',    9],
      [ 'V',    5],
      ['IV',    4],
      [ 'I',    1]
    ];
    
export const toRoman = (decimal) =>
chart.reduce((acc, numeral) => {
  const [roman, remainder] = acc;
  const [letter, value] = numeral;
  return [roman + letter.repeat(remainder / value),
          remainder % value];
}, ['', decimal])[0];
  

export const a2s = (arr) => {
  let str = '';
    for (let i = 0; i < arr.length; i++ ) {
      if (Array.isArray(arr[i])) {
        str += `${a2s(arr[i])} `;
      } else str += `${arr[i]} `;
    }
    return str;
}

export function capitalize( word ) {
  
  return !word || word === ''
   ? false
   : word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export function decapitalize(word) {
  return word.toLowerCase();
}
