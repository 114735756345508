import React from "react";
import { Link } from "react-router-dom";
import Page from "../Page";
import { lost } from '../lib/poems';
import Snippet from "../components/Snippet";

export default function NotFoundPage() {
  return (
    <Page className={`notfound-page bg-404`}>
      <section className="section">
        <div className="box">
          <h1>Lost</h1>
          <Snippet md={lost} />
          <div className="button-home">
            <Link to="/">
              <button className="lt-btn l">Get home</button>
            </Link>
          </div>
        </div>
      </section>
    </Page>
  );
}
