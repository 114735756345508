import React from "react";
import ReactMarkdown from "react-markdown";

export default function Snippet({ md, className }) {

  return (
    <div className={`snippet ${className = ''}`}>
      <ReactMarkdown skipHtml>{md}</ReactMarkdown>
    </div>
  );
}
