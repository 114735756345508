import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import store from "store2";
import DOMPurify from "dompurify";
import LetterDance from "./LetterDance";
import { isMobile } from "react-device-detect";
import Hero from "./Hero";
import icon1 from "../assets/images/icon-pen.png";
import icon2 from "../assets/images/i-2.png";
import icon3 from "../assets/images/i-3.png";
import { useTranslation } from "react-i18next";
import Page from "../Page";
import Select from "react-select";
import "./Start.scss";

Array.prototype.random = function () {
  return this[Math.floor(Math.random() * this.length)];
};

export default function StartPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onStart = async () => {
    navigate("/edit");
  };

  const handleKey = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      onStart(e.currentTarget.value);
    }
  };

  const reset = () => {
    store.remove("seed");
    store.remove("poem");
    store.remove("author");
    store.remove("n");
    store.remove("src");
    store.remove("fontSize");
    store.remove("fontColor");
    store.remove("selBg");
    store.remove("dataUrl");
    store.remove("position_author");
    store.remove("position_text");
    store.remove("genre");
  };

  useEffect(() => {
    reset();
  }, []);

  const Features = () => {
    return (
      <section className="details">
        <div className="features">
          <div className="feature">
            <span className="feature-icon">
              <img src={icon1} />
            </span>
            <div className="feature--wrapper">
              <h3>Compose</h3>
              <p>
                Forget about writer's blocks. Produce rich, high value poetry
                for joy and money.
              </p>
            </div>
          </div>

          <div className="feature">
            <span className="feature-icon">
              <img src={icon3} />
            </span>
            <div className="feature--wrapper">
              <h3>Preserve</h3>
              <p>{t("preserve_text")}</p>
            </div>
          </div>

          <div className="feature">
            <span className="feature-icon">
              <img src={icon2} />
            </span>
            <div className="feature--wrapper">
              <h3>Share</h3>
              <p>{t("share_text_2")}</p>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <Page>
      <Hero>
        <LetterDance size="m" />
      </Hero>

      <div className={`start--wrapper`}>
        <section className="start">
          <h2 className="tagline">
            Welcome to Stanza, an AI tool for composing short poems!
          </h2>
          <p>
            This experimental app is created to help poetry lovers compose short
            poems (such as haikus, Nostradamus-style quatrains, epigrams, etc) —
            not as a substitute for human talent, but as a source of inspiration.
            To begin, enter one or two words representing your poem's theme, select
            genre, and click the "GO" button.
          </p>
          {/* <p>
            The resulting poems can be transformed into a PNG image with a
            custom background and downloaded to share on social networks or send
            in email. NFTs will be available soon.
          </p> */}
          {/* For those who desire to secure their author rights, we also offer the option to save your poem as an NFT (Polygon ERC-721 on IPFS). 
          These NFTs can be traded on popular marketplaces such as Opensea or Binance, or you can send them as a one-of-a-kind gift to your loved one's Metamask wallet.*/}

          <div className={`form`}>
            <input
              type="text"
              className="start-input"
              onChange={(e) =>
                store.set("seed", DOMPurify.sanitize(e.target.value.trim()))
              }
              onKeyDown={(e) => handleKey(e)}
              placeholder="Theme (one or two words)"
            />

            <Select // genre
              options={[
                {
                  label: "quatrain",
                  value: "quatrain",
                },
                {
                  label: "haiku",
                  value: "haiku",
                },
                {
                  label: "limerick",
                  value: "limerick",
                },
                {
                  label: "epigram",
                  value: "epigram",
                },
                {
                  label: "epitaph",
                  value: "epitaph",
                },
                {
                  label: "stanza",
                  value: "stanza",
                },
              ]}
              onChange={(e) => {
                store.set("genre", e.value);
              }}
              className="lt-select"
            />

            <button className="lt-btn m" onClick={() => onStart()}>
              GO
            </button>

            {isMobile && <Notice />}
          </div>

          <Features />
        </section>
      </div>
    </Page>
  );
}
