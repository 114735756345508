export default function IconDownload() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
    >
      <rect width="48" height="48" />
      <path
        fill="currentColor"
        d="M24,31.4l-9.6-9.6l2.1-2.1l6,6V7h3v18.5l6-6l2.2,2.1C33.7,21.7,24,31.3,24,31.4z M11,39c-0.8,0-1.5-0.3-2.1-0.9
	C8.3,37.5,8,36.8,8,36v-7.1h3V36h26v-7.1h3V36c0,0.8-0.3,1.5-0.9,2.1C38.5,38.7,37.8,39,37,39H11z"
      />
    </svg>
  );
}
