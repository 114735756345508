import React, {useEffect} from "react";
import AvatarEditor from 'react-avatar-editor';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import { useArtContext } from '../contexts/ArtContext';
import {bgs} from '../assets/bgs/bgs';
import store from "store2";


export default function BgImage () {

  const windowDimensions = useWindowDimensions();
  const {palNum} = useArtContext();

  useEffect( () => {
    store.set('selBg', {data: bgs[palNum], type: 'img'});
  }, []);

  return ( 
    <>
      <AvatarEditor
        className="artboard bg-img"
        image={bgs[palNum]}
        width={windowDimensions.w}
        height={windowDimensions.h}
        border={0}
        color={[255, 255, 255, 0.6]} 
        rotate={0}
      />
     </>
  )
}
