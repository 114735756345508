import React, { useEffect, useState } from "react";
import { SpinnerCircularFixed } from "spinners-react";
import "./Loader.scss";

export default function Loader( { className = 'fullscreen', setLoading }) {
  return (
  <div className={`loader-${className}`} onKeyDown={(e) => setLoading(false)}>
      <div className="spinner">
        <SpinnerCircularFixed
          size={77}
          thickness={173}
          speed={93}
          color="rgba(244, 24, 75, 0.9)"
          secondaryColor="rgba(127, 255, 212, 1)"
        />
      </div>
    </div>
  )
}
