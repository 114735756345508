import React from "react";
import { useTranslation } from "react-i18next";
// import LSwitch from "./LSwitch";
import { site } from "../lib/constants";
import IconTwitter from "../assets/icons/IconTwitter.js";
import IconEmail from "../assets/icons/IconEmail.js";
import "./Footer.scss";

export default function Footer( { reset }) {
  const {t} = useTranslation();
  return (
    <div className="page-footer">

      <nav className="footer__nav">
        <span className="col smm-icons">
          <a className="line smm-icon twitter" href="https://martlet.live">
            <IconTwitter />
          </a>
          <a className="line smm-icon twitter" href="mailto:martlet@martlet.live">
            <IconEmail />
          </a>
        </span>

        <span className="col">
          <a className="line" href="/credits">{t('credits')}</a>
          <a className="line" href="/disclaimer">{t('disclaimer')}</a>
        </span>

        <span className="col">&copy; 2022-2023, martlet.live</span>
        
      </nav>
      {/* <LSwitch /> */}
    </div>
  );
}
