import React, { useCallback, useRef, useState, useEffect } from "react";
import store from "store2";

const DND = ({ elm, children }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const elementRef = useRef(null);


  useEffect( () => {
    const pos = store.has(`position_${elm}`) && store.get(`position_${elm}`);
    elementRef.current.style.transform = `translate(${pos.x}px, ${pos.y}px)`;
  }, []);

  const onMouseDown = useCallback(
    () => {
      const onMouseMove = (e) => {

        position.x += e.movementX;
        position.y += e.movementY;
        elementRef.current.style.transform = `translate(${position.x}px, ${position.y}px)`;
        setPosition(position);
        store.set(`position_${elm}`, position);
      };
      const onMouseUp = () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
      };
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    },
    [position, setPosition, elementRef]
  );

  return (
      <div className="dnd" ref={elementRef} onMouseDown={onMouseDown}>
        {children}
      </div>
  );
};

export default DND;

