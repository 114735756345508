import React from "react";

export default function Hero(props) {
  return (
    <div className="hero--wrapper">
      <div className="hero">
        {props.children}
        <div className="hero--wrapped">
          
        </div>
      </div>
    </div>
  );
}
