import React, { useEffect } from "react";
import store from "store2";
import IconTrash from "../assets/icons/Trash";
import "./Drafts.scss";

export default function Drafts({
  drafts,
  setDrafts,
  setPoem,
}) {


  useEffect(() => {
    const drs = store.has("drafts") ? store.get("drafts") : [];
    drs && setDrafts(drs);
  }, [setPoem, setDrafts]);

  const deleteDraft = (id) => {
    setDrafts((prev) => prev.filter((p) => p.id !== id));
    store.set(
      "drafts",
      store.get("drafts").filter((d) => d.id !== id)
    );
  };

  const restoreDraft = (id) => {
    const drs = store.has("drafts") ? store.get("drafts") : [];
    if (drs.length) {
      const the = drs.find((dr) => dr.id === id);
      setPoem(the.poem);
      store.set('poem', the.poem);
    }
  };

  return (
    <div className="drafts-grid">
      {drafts?.slice(0).reverse().map((d, i) => ( i <= 64 && (
        <div key={i} className="draft--wrapper">
          <div className="draft" onClick={() => restoreDraft(d.id)}>
            {d.poem}
          </div>
          <span className="icon-btn" onClick={() => deleteDraft(d.id)}>
            <IconTrash />
          </span>
        </div>
      )))}
    </div>
  );
}
