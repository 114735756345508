import React, { useRef, useEffect, useState } from "react";
import DOMPurify from "dompurify";
import DND from './DND';
import Resizer from 'resizer-cl';
import Textarea from 'react-textarea-autosize';
import store from "store2";


export default function Textboard ({
  author,
  setAuthor,
  poem,
  setPoem,
  editing,
  fontSize,
  fontColor,
}) {

  const textRef = useRef(null);
  const placeholder1 = 'Click the pencil button to start writing';
  const placeholder2 = 'Author credits';
  const [rows, setRows] = useState(5);

  useEffect( () => {
    setRows(poem.match(/\n/g)?.length + 1 || 5);
  }, [poem]);

  new Resizer('textarea', {
    grabSize: 20,
    resize: 'both',
    handle: 'handle',
});


  return (

    <div className="present-text">
      <DND elm="poem">
        <div className={`text--wrapper ${editing ? 'editing' : ''}`}>
          <span className={`move-handle ${fontColor ? 'white' : 'black'}`}>
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" focusable="false"><path d="M8 7h2V5H8v2zm0 6h2v-2H8v2zm0 6h2v-2H8v2zm6-14v2h2V5h-2zm0 8h2v-2h-2v2zm0 6h2v-2h-2v2z"></path></svg>
          </span>
          <Textarea 
            style={{ whiteSpace: 'pre-wrap' }}
            rows={rows}
            minRows={4}
            className={`text ${fontColor ? 'white' : 'black'} size-${fontSize * 10}`}
            value={poem}
            onChange={(e) => {
              setPoem(DOMPurify.sanitize(e.target.value.trim()));
              store.set('poem', DOMPurify.sanitize(e.target.value.trim()));
            }}
            spellCheck={false}
            disabled={!editing}
            placeholder={placeholder1}
            ref={textRef}
          />
          <span className={`resize-handle ${fontColor ? 'white' : 'black'}`}></span>
        </div>
      </DND>
      {/* <DND elm="author">
        <div className={`author-wrapper ${editing ? 'editing' : ''}`}>
          <span className={`move-handle ${fontColor ? 'white' : 'black'}`}>
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" focusable="false"><path d="M8 7h2V5H8v2zm0 6h2v-2H8v2zm0 6h2v-2H8v2zm6-14v2h2V5h-2zm0 8h2v-2h-2v2zm0 6h2v-2h-2v2z"></path></svg>
          </span>
          <input  
            className={`author ${fontColor ? 'white' : 'black'}`}
            defaultValue={author || ``}
            onChange={(e) => setAuthor(DOMPurify.sanitize(e.currentTarget.value.trim()))}
            spellCheck={false}
            disabled={!editing}
            placeholder={placeholder2}
          />
        </div>
      </DND> */}
    </div>
  );
}

