import {bgThumbs} from "../assets/bgs/bgs";

export const hsla2css = ( [h, s, l], a ) => `hsla(${h}, ${s}%, ${l}%, ${a})`;

export const colorPalettes = [
  [
    [309, 70, 32],
    [259, 47, 52],
    [219, 95, 28],
    [207, 87, 57],
    [181, 100, 80],
  ],
  [
    [344, 95, 48],
    [321, 79, 46],
    [196, 95, 48],
    [158, 87, 59],
    [45, 95, 48],
  ],
  [
    [281, 62, 60],
    [323, 74, 62],
    [173, 100, 46],
    [60, 81, 55],
    [28, 97, 62],
  ],
  [
    [189, 66, 18],
    [190, 12, 35],
    [64, 5, 48],
    [69, 23, 57],
    [72, 73, 54],
  ],
  [
    [253, 87, 60],
    [236, 95, 28],
    [11, 96, 33],
    [0, 95, 48],
    [106, 96, 33],
  ],
  [
    [184, 18, 12],
    [188, 13, 22],
    [16, 60, 40],
    [0, 27, 42],
    [185, 4, 52],
  ],
  [
    [200, 95, 9],
    [197, 75, 30],
    [170, 51, 51],
    [126, 66, 76],
    [91, 94, 86],
  ],
  [
    [183, 100, 15],
    [23, 100, 30],
    [24, 100, 15],
    [183, 100, 30],
    [183, 83, 38],
  ],
  [
    [0, 86, 62],
    [0, 43, 45],
    [21, 41, 38],
    [22, 53, 68],
    [180, 21, 93],
  ],
  [
    [304, 89, 51],
    [237, 88, 23],
    [237, 85, 45],
    [236, 85, 13],
    [158, 96, 43],
  ],
  [
    [207, 96, 12],
    [157, 95, 17],
    [146, 96, 22],
    [140, 96, 33],
    [134, 96, 43],
  ],
  
  [
    [218, 50, 5],
    [218, 100, 50],
    [190, 100, 50],
    [218, 100, 40],
    [0, 0, 15],
  ],
  [
    [218, 77, 12],
    [0, 0, 0],
    [210, 75, 20],
    [0, 0, 0],
    [206, 10, 10],
  ],
  [
    [342, 100, 40],
    [342, 100, 20],
    [0, 0, 0],
    [342, 100, 60],
    [350, 90, 10],
  ],
  [
    [0, 85, 53],
    [345, 0, 30],
    [345, 75, 50],
    [195, 75, 50],
    [206, 13, 68],
  ],
  [
    [195, 85, 53],
    [265, 64, 40],
    [340, 85, 53],
    [25, 85, 53],
    [50, 90, 75],
  ],
  [
    [48, 100, 50],
    [26, 100, 60],
    [11, 94, 42],
    [50, 100, 43],
    [65, 6, 34],
  ],
  [
    [346, 86, 50],
    [30, 85, 53],
    [60, 85, 53],
    [109, 85, 53],
    [200, 85, 53],
  ],
  [
    [334, 80, 61],
    [258, 72, 46],
    [201, 91, 55],
    [26, 76, 49],
    [52, 100, 81],
  ],
  [
    [229, 92, 20],
    [215, 79, 31],
    [0, 85, 46],
    [194, 100, 60],
    [206, 13, 68],
  ],
  [
    [342, 100, 68],
    [342, 85, 41],
    [334, 24, 61],
    [330, 5, 31],
    [0, 0, 20],
  ],
  [
    [218, 100, 14],
    [218, 100, 35],
    [218, 100, 50],
    [190, 100, 50],
    [218, 100, 5],
  ],
  [
    [226, 100, 32],
    [0, 0, 10],
    [100, 100, 50],
    [0, 0, 12],
    [0, 0, 0],
  ],
];

export const makeBg = (p, t) => {
  switch (t) {
    case "gradient1":
      return `linear-gradient(120deg, 
        ${hsla2css(p[0], 0.5)} 0%, 
        ${hsla2css(p[1], 0.5)} 25%, 
        ${hsla2css(p[2], 0.5)} 60%,
        ${hsla2css(p[3], 0.5)} 80%,
        ${hsla2css(p[4], 0.5)} 100%),
      linear-gradient(135deg, 
        ${hsla2css(p[0], 0.75)} 0%,   
        ${hsla2css(p[1], 0.75)} 25%, 
        ${hsla2css(p[2], 0.75)} 60%,
        ${hsla2css(p[3], 0.75)} 80%,
        ${hsla2css(p[4], 0.75)} 100%),
      linear-gradient(150deg, 
        ${hsla2css(p[0], 1)} 0%, 
        ${hsla2css(p[1], 1)} 25%, 
        ${hsla2css(p[2], 1)} 60%,
        ${hsla2css(p[3], 1)} 80%,
        ${hsla2css(p[4], 1)} 100%)`;

    case "gradient2":
      return `linear-gradient(127deg, 
        ${hsla2css(p[0], 0.8)},
        ${hsla2css(p[1], 0.1)} 72%),
      linear-gradient(217deg, 
        ${hsla2css(p[2], 0.8)},
        ${hsla2css(p[3], 0.1)} 72%),
      linear-gradient(337deg, 
        ${hsla2css(p[4], 0.8)},
        ${hsla2css(p[0], 0.1)} 72%)`;

    case "lowpoly":
      return `linear-gradient(120deg, 
        ${hsla2css(p[0], 1)} 0%, 
        ${hsla2css(p[1], 1)} 25%, 
        ${hsla2css(p[2], 1)} 50%,
        ${hsla2css(p[3], 1)} 75%,
        ${hsla2css(p[4], 1)} 100%)`;
  }
};

export const bgList = () => {
  const regularTypes = ["gradient1", "gradient2", "lowpoly"];

  let bgs = [];

  regularTypes.map( t => {
    colorPalettes.map((p, nr) => {
      bgs.push({
        t,
        nr,
        bg: makeBg(p, t),
      })
    })
  });
  bgThumbs.map((p, nr) => {
    bgs.push({
      t: "img",
      nr,
      bg: p,
    });
  });

  return bgs;
};

