import React, { useEffect, useRef } from "react";
import LowPoly from "./LowPoly";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { useArtContext } from "../contexts/ArtContext";
import store from "store2";
import { colorPalettes } from "../lib/color-helpers";

export default function BgPoly() {
  const canvasRef = useRef(null);
  const {secOpen, palNum} = useArtContext();
  const winDim = useWindowDimensions();
  const {w, h} = winDim;
  let width = w;
  let height = h;
  
  if (w > h && secOpen) {
    if ( w < 1200) {
      height = h - 70;
    } else if (w >= 1200 && w < 1440) {
      width = h - 70;
      height = h - 70;
    } else {
      width = w - 540;
      height = h - 70;
    } 
  }

  const alpha = 1;
  const variance = [15, 20, 35].random();
  const depth = [10, 15].random();
  const dither = [10, 12, 15].random();
  const cellSize = [10, 15, 20, 25, 30, 35, 40, 45, 50].random();
  const length = 5;
  const colors = colorPalettes[palNum];

  useEffect( () => {
    store.set('storedBgData', {data: colors, type: 'lowpoly'});
  }, []);


  const drawCanvas = (
    element,
    width,
    height,
    alpha,
    variance,
    depth,
    dither,
    cellSize,
    colors,
    length
  ) => {
    const cvs = new LowPoly(
      element,
      width,
      height,
      alpha,
      variance,
      depth,
      dither,
      cellSize,
      colors,
      length
    );
    cvs.render();
  };

  useEffect(() => {
    if (colors) {
      drawCanvas(
        canvasRef.current,
        width,
        height,
        alpha,
        variance,
        depth,
        dither,
        cellSize,
        colors,
        length
        );
    }
    
  }, [colors, secOpen, w, h]);

  return (
    <canvas
      className="bg-poly"
      width={width}
      height={height}
      ref={canvasRef}
    ></canvas>
  );
}
