import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import StartPage from "./components/StartPage";
import EditPage from "./components/EditPage";
import CollectionPage from "./components/CollectionPage";
import SinglePage from "./components/TokenCardSinglePage";
import CreditsPage from "./pages/CreditsPage";
import DisclaimerPage from "./pages/DisclaimerPage";
import NotFound from "./pages/NotFoundPage";
import { Helmet } from 'react-helmet-async';
import {site} from './lib/constants';
import CookieConsent from "react-cookie-consent";

export default function App() {
  const location = useLocation();
  const page = location.pathname === '/' ? 'home' : location.pathname.substring(1);

  const openGraphData = {
    title: `Stanza`,
    description: `Short poem helper`,
    url: window.location.href,
  };

  return (
    <>
      <Helmet>
        <title>{openGraphData.title}</title>
        <meta name="description" content={openGraphData.description} />
        <meta property="og:title" content={openGraphData.title} />
        <meta property="og:description" content={openGraphData.description} />
        <meta property="og:url" content={openGraphData.url} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ult" />
        <meta name="twitter:creator" content="@ult" />
        <meta
          property="og:image"
          content={`https://${window.location.host}/smm_twitter.jpg`}
        />
      </Helmet>

      <div className={`app ${site} ${page}`}>

        <CookieConsent
          location="bottom"
          buttonText="Accept cookies"
          cookieName="Consent"
          style={{ background: "black" }}
          buttonStyle={{ background: "aquamarine" }}
          buttonClasses={"lt-btn s alt"}
          expires={1500}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>

        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<StartPage />} />
          <Route path="/edit" element={<EditPage />} />
          {/* <Route path="/assets" element={<CollectionPage />} /> */}
          {/* <Route path="/:chainId/:id" element={<SinglePage />} /> */}
          <Route path="/credits" element={<CreditsPage />} />
          <Route path="/disclaimer" element={<DisclaimerPage />} />
          <Route path="" element={<Navigate to="/notfound" />} />
        </Routes>
      </div>
    </>
  );
}
