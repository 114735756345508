import React, {useRef} from "react";
import store from "store2";
import DOMPurify from "dompurify";
import ColorToggle from "./ColorToggle";
import { nanoid } from "nanoid";
import IconEdit from "../assets/icons/Edit";
import IconCancel from "../assets/icons/IconCancel";
// import IconRefresh from "../assets/icons/IconRefresh";
// import IconCopy from "../assets/icons/IconCopy";
import IconDownload from "../assets/icons/IconDownload";
import { csMatrix } from "../lib/cs-matrix";
import { enMatrix } from "../lib/en-matrix";
import DropZone from "./DropZone";
import { clean, generatePo } from "../lib/langprocessing";
import { useTranslation } from "react-i18next";
import { useArtContext } from "../contexts/ArtContext";
import Drafts from "./Drafts";
// import Mint from "./Mint";
import {Logo, Burger} from "./TopNav";
import { useWindowDimensions } from "../hooks/useWindowDimensions";

import "./TopNav.scss";

import "./Controls.scss";

const Controls = ({
  poem,
  setPoem,
  seed,
  setVocab,
  author,
  setAuthor,
  save,
  editing,
  setEditing,
  fontColor,
  setFontColor,
  fontSize,
  crease,
  drafts,
  setDrafts,
  setSrc,
}) => {
  const dRef = useRef();
  const {start, secOpen} = useArtContext();
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const matrix = lang === "en" ? enMatrix : csMatrix;
  const {w, h} = useWindowDimensions();


  const onSave = () => {
    const te = poem;
    if (!te) return;
    const cleant = clean(te);
    if (cleant) {
      setPoem(cleant);
      store.set("poem", cleant);
    }

    const au = author;
    const cleana = clean(au);
    cleana && setAuthor(cleana);
    store.set("author", cleana);

    setEditing(false);
  };
/*
  const onRefresh = async () => {
    let po = await generatePo(lang, seed);
    if (po) {
      setVocab(po);
      setPoem(prev => prev + '\n\n---\n\n' + po);
      store.set("poem", store.get("poem") + '\n\n---\n\n' + po);
    }
  };

  const addDraft = () => {
    const id = nanoid();
    const t = DOMPurify.sanitize(poem.trim());
    const dr = {
      id,
      poem: t,
    };
    store.has("drafts")
      ? store.set("drafts", [...store.get("drafts"), dr])
      : store.set("drafts", [dr]);
    setDrafts((prev) => [...prev, dr]);
  };
*/
  const FontSizeSpinner = () => {
    return (
      <span className="font-size-spinner">
        <span title="Decrease Font Size" onClick={() => crease("de", fontSize)} className={`spinner minus`}>
          &mdash;
        </span>
        <span
          title="Invert Text Color"
          onClick={() => {
            store.set('fontColor', !fontColor);
            setFontColor(!fontColor);
          }}
          className={`spinner letter ${fontColor ? 'white' : 'black'}`}
        >
          A
        </span>
        <span title="Increase Font Size" onClick={() => crease("in", fontSize)} className={`spinner plus`}>
          &#xFF0B;
        </span>
      </span>
    );
  };

  return (
    <div className={`controls ${w > 540 ? 'not-small-screen' : ''}`}>

      {!start &&
        <div className="drafts" ref={dRef}>
          <Drafts
            poem={poem}
            setPoem={setPoem}
            drafts={drafts}
            setDrafts={setDrafts}
          />
        </div>
      }

      {w > 540 && !secOpen &&
        <div className="group">
          <Logo />
        </div>
      }

      <div className="group">
        {!editing && (
          <span title="Click to unlock editing" className="btn-icon icon-edit attention" onClick={() => setEditing(true)}>
            <IconEdit />
          </span>
        )}
{/* 
        {!editing && (
          <span title="Refresh vocabulary" className="btn-icon icon-refresh" onClick={() => {
            addDraft();
            onRefresh();
          }}>
            <IconRefresh />
          </span>
        )} */}

        {editing && (
          <span title="Done editing" className="btn-icon icon-cancel" onClick={() => {
            setEditing(false);
          }}>
            <IconCancel />
          </span>
        )}
{/* 
        {!editing && (
          <span title="Make draft" className="btn-icon icon-draft" onClick={() => addDraft()}>
            <IconCopy />
          </span>
        )} */}

        <FontSizeSpinner />

        <ColorToggle />

        <DropZone setSrc={setSrc} />

        {editing && (
          <button className="lt-btn s" onClick={() => onSave()}>
            {t("save")}
          </button>
        )}

        {!editing && (
          <span title="Download Image" className="btn-icon download" onClick={() => {  
            dRef.current.style.display = "none";
            save();
          }}>
            <IconDownload />
          </span>
        )}

        {/* {!editing &&
          <Mint
            makeBlob={makeBlob}
            author={author}
            reset={reset}
            poem={poem}
          />
        } */}

      </div>
        
      <div className="group">
        <Burger />
      </div>
    </div>
  );
};

export default Controls;


