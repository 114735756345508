import { func } from "prop-types";


 
export class PRNG {
  constructor(seed) {
    this.seed = seed % 2147483647;
    if (this.seed <= 0) {
      this.seed += 2147483646;
    }
  }

  /**
   * Returns a pseudo-random value between 1 and 2^32 - 2.
   */
  next() {
    this.seed = (this.seed * 16807) % 2147483647;
    return this.seed;
  }

  /**
   * Returns a pseudo-random floating point number in range [0, 1).
   */
  generate(min = 0, max = 1) {
    // We know that result of next() will be 1 to 2147483646 (inclusive).
    return min + ((this.next() - 1) / 2147483646) * (max - min);
  }
}


export function componentToHex(src) {
  let c = Math.floor(src);
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}
export function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function hslToCss(h, s, l) {
  return `hsl(${h},${s}%,${l}%)`;
}



export function hslToHex(h, s, l) {
  if (Array.isArray(h)) {
    s = h[1];
    l = h[2];
    height = h[0];
  }

  height /= 360;
  s /= 100;
  l /= 100;

  let r;
  let g;
  let b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, height + 1 / 3);
    g = hue2rgb(p, q, height);
    b = hue2rgb(p, q, height - 1 / 3);
  }
  const toHex = (x) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export const getRandomHex = (bright = false) => {
  if (bright) {
    return hslToHex(Math.floor(Math.random() * 360), 85, 53);
  }
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};


export function hsl2hex([h, s, l]) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function hsl2rgb (h, s, l) 
{
  let a = s * Math.min(l, 1-l);
  let f = (n,k = (n + h /30)%12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
  return [f(0), f(8), f(4)];
}   

function channel2hex(color) {
  var hexadecimal = color.toString(16);
  return hexadecimal.length == 1 ? "0" + hexadecimal : hexadecimal;
}

export function rgb2hex(red, green, blue) {
  return "#" + channel2hex(red) + channel2hex(green) + channel2hex(blue);
}
